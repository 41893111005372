/* imports and utilities like animations and mixins should be defined here */
@import '~@xpo-ltl/ngx-ltl-core/styles/variables';
@import '~@xpo-ltl/ngx-ltl-core/styles/reset';
@import './flexboxgrid.scss';

$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.full-width {
  width: 100%;
}

[mat-icon-button].mat-icon-button {
  height: auto;
}

.field-group {
  display: flex;
  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
}

.field {
  display: flex;
  flex-direction: column;
  margin-right: $xpo-contentSpacing * 2;
  &__label {
    font-weight: bold;
  }
  &:last-child {
    margin-right: unset;
  }
}
